/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import '../../../util/layui/css/layui.css'
import '../../../util/layui/layui.js'
import CheckInService from '../../../api/CheckInService.js'

export default {
	name: 'ManuscriptEdit',
	data() {
		return {
			manuscriptInfo: '',
			keyword: '',
			keywordList: [],
			ManuName: '', //名称
			manukeywordsList: [], //关键词
			manusummary: '', //简介
			manutheme: '', //选题特点
			resFilepath: '', //文件路径
			manuabstract: '', //摘要
			resName: '', //文件名
			userName: '', //用户名称
			usertel: '', //用户手机号
			usersex: -1, //性别
			userbirthday: '', //生日
			usereducation: '', //学历
			userwork: '', //工作地点
			userresearch: '', //工作方向
			userintroduction: '', //个人简介
			useraddress: '', //地址
			ranger: { //限制可选日期
				disabledDate(time) {
					var startTime = new Date('1899-12-31');
					return time.getTime() > Date.now() || time.getTime() < startTime;
				},
			},
		}
	},
	methods: {
		GetManuscriptInfo() { //获取投稿详情
			var that = this
			CheckInService.GetManuscriptInfo(that.$route.query.id).then(res => {
				if (res.Tag == 1) {
					that.manuscriptInfo = res.Result
					that.ManuName = that.manuscriptInfo.Title
					that.manusummary = that.manuscriptInfo.Summary?that.manuscriptInfo.Summary:''
					that.manutheme = that.manuscriptInfo.Theme?that.manuscriptInfo.Theme:''
					that.manuabstract = that.manuscriptInfo.Abstract?that.manuscriptInfo.Abstract:''
					that.userName = that.manuscriptInfo.Author?that.manuscriptInfo.Author:''
					that.usertel = that.manuscriptInfo.Tel?that.manuscriptInfo.Tel:''
					that.usersex = that.manuscriptInfo.Sex?that.manuscriptInfo.Sex:-1
					that.userbirthday = that.manuscriptInfo.Birthday?that.manuscriptInfo.Birthday:''
					that.usereducation = that.manuscriptInfo.Education?that.manuscriptInfo.Education:''
					that.userwork = that.manuscriptInfo.Work?that.manuscriptInfo.Work:''
					that.userresearch = that.manuscriptInfo.Research?that.manuscriptInfo.Research:''
					that.userintroduction = that.manuscriptInfo.Introduction?that.manuscriptInfo.Introduction:''
					that.useraddress = that.manuscriptInfo.Address?that.manuscriptInfo.Address:''
					that.manukeywordsList = that.manuscriptInfo.KeyWordList
					that.resFilepath = that.manuscriptInfo.FileModel.ResPath?that.manuscriptInfo.FileModel.ResPath:'',
					that.resName = that.manuscriptInfo.FileModel.ResName?that.manuscriptInfo.FileModel.ResName:''
					var val = '';
					var valName = '';
					that.manukeywordsList.forEach(item => {
						var labelHTML = "<li data='" + item.KeyWordID + "'" + "data-name='" + item.Keyword + "'>" + item.Keyword +
							" x</li>";
						$(".label-selected").append(labelHTML);
						$(".label-item").find("li[data='" + item.KeyWordID + "']").addClass("selected");
						val += item.KeyWordID + ',';
						valName += item.Keyword + ',';
					})
					$("#keywords").val(val);
					$("#keywordnames").val(valName);
					that.ShowKeyWords()
				}
			})
		},
		GetKeywords() { //获取关键词
			var that = this
			CheckInService.GetKeywords(that.keyword.trim()).then(res => {
				if (res.Tag == 1) {
					if (that.keywordList.length != 0) {
						that.keywordList = res.Result
						$(".label-item").find("li").remove();
						var html = '';
						for (var i in that.keywordList) {
							html += "<li data=\"" + that.keywordList[i].id + "\"><span>" + that.keywordList[i].element_name +
								"</span></li>"; //拼接标签
						}
						$(".label-item").append(html);
						that.manukeywordsList.forEach(item => {
							$(".label-item").find("li[data='" + item.KeyWordID + "']").addClass("selected");
						})
					} else {
						that.keywordList = res.Result
					}
				}
			})
		},
		uploadFile() { //上传文件
			var that = this
			var file = that.$refs.uploadFile.files[0]
			var fileExtension = '.'+file.name.split('.').[file.name.split('.').length-1]
			if(fileExtension != '.doc' && fileExtension != '.docx' && fileExtension != '.txt' && fileExtension != '.pdf'){
				that.$alert('请选择正确的文件（txt、pdf、doc、docx）', '提示', {
					confirmButtonText: '确定'
				});
				return
			}
			var reader = new FileReader();
			reader.onloadend = function() {
				var fileData = reader.result
				var str = fileData.substring(fileData.indexOf("base64") + 7);
				var strLength = str.length;
				var fileLength = parseInt(strLength - (strLength / 8) * 2);
				// 由字节转换为KB
				var size = "";
				size = parseInt((fileLength / 1024 / 1024).toFixed(2));
				if(size > 40){
					that.$alert('请选择大小40M以内的文件', '提示', {
						confirmButtonText: '确定'
					});
					return
				}
				var uploadfileData = [{'FileBuffer':fileData.substring(fileData.indexOf("base64") + 7),'FileExtension':fileExtension}]
				CheckInService.FileUpload('Manuscript',uploadfileData).then(res=>{
					console.log(res)
					if(res.Tag == 1){
						that.resName = file.name
						that.resFilepath = res.Result[0]
					}
				})
			};
			if (file) {
				reader.readAsDataURL(file);
			}
		},
		DeleteFile(){//删除上传文件
			this.resName = ''
			this.resFilepath = ''
		},
		ChangeKeyword() { //换一批关键词
			this.keyword = ''
			this.GetKeywords()
		},
		chooseUserSex(sex) { //选择性别
			this.usersex = sex
		},
		CommitManuscript(){ //提交
			var that = this
			if(that.CheckContent()){
				var keywords = $("#keywords").val()
				var keywordNames = $("#keywordnames").val()
				CheckInService.EditManuscript(that.$route.query.id,that.ManuName.trim(),keywords,keywordNames,that.manusummary.trim(),that.manutheme.trim(),that.manuabstract.trim(),that.resFilepath,that.resName,that.$UserID,that.userName.trim(),that.usertel.trim(),that.usersex,that.userbirthday,that.usereducation == '请选择学历'?'':that.usereducation,that.userwork.trim(),that.userresearch.trim(),that.userintroduction.trim(),that.useraddress.trim()).then(res=>{
					if(res.Tag == 1){
						that.$router.push({
							path:'/UserManuscriptList'
						})
					}
				})
			}
		},
		CheckContent(){ //检测输入信息
			var that = this
			var keywords = $("#keywords").val()
			if(that.ManuName.trim() == ''){
				$('#manuName input').addClass('error')
				$('#manuName .zxtgerror').text('请输入选题名称')
				window.scrollTo(0,$('#manuName').offset().top-150)
				return
			}else if(that.ManuName.trim().length > 30){
				$('#manuName input').addClass('error')
				$('#manuName .zxtgerror').text('请输入限于30字的选题名称')
				window.scrollTo(0,$('#manuName').offset().top-150)
				return
			}else if(!keywords){
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').addClass('error')
				$('#manuKeyword .zxtgerror').text('请选择关键词，限5个')
				window.scrollTo(0,$('#manuKeyword').offset().top-150)
				return
			}else if(that.manusummary.trim() == ''){
				$('#manuSummary textarea').addClass('error')
				$('#manuSummary .zxtgerror').text('请输入作品简介')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#manuSummary').offset().top-150)
				return
			}else if(that.manusummary.trim().length > 1000){
				$('#manuSummary textarea').addClass('error')
				$('#manuSummary .zxtgerror').text('请输入作品简介,限1000字')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#manuSummary').offset().top-150)
				return
			}else if(that.manutheme.trim().length > 1000){
				$('#manuTheme textarea').addClass('error')
				$('#manuTheme .zxtgerror').text('请输入选题特点,限1000字')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#manuTheme').offset().top-150)
				return
			}else if(that.manuabstract.trim().length > 1000){
				$('#manuAbstract textarea').addClass('error')
				$('#manuAbstract .zxtgerror').text('请输入作品摘要,限1000字')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#manuAbstract').offset().top-150)
				return
			}else if(!that.resFilepath){
				$('#manuFile .zxtgerror').text('请上传文件')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#manuFile').offset().top-150)
				return
			}else if(that.userName.trim() == ''){
				$('#userName input').addClass('error')
				$('#userName .zxtgerror').text('请输入姓名')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userName').offset().top-150)
				return
			}else if(that.userName.trim().length > 20){
				$('#userName input').addClass('error')
				$('#userName .zxtgerror').text('请输入姓名,限20字')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userName').offset().top-150)
				return
			}else if(that.usertel.trim() == ''){
				$('#userTel input').addClass('error')
				$('#userTel .zxtgerror').text('请输入手机号')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userTel').offset().top-150)
				return
			}else if(!(/^1[3456789]\d{9}$/.test(that.usertel.trim()))){
				$('#userTel input').addClass('error')
				$('#userTel .zxtgerror').text('请输入正确格式手机号')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userTel').offset().top-150)
				return
			}else if(that.userresearch.trim().length > 20){
				$('#userResearch input').addClass('error')
				$('#userResearch .zxtgerror').text('请输入工作/研究方向，限20字')
				$('#userTel input').removeClass('error')
				$('#userTel .zxtgerror').text('')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userResearch').offset().top-150)
				return
			}else if(that.userwork.trim().length > 20){
				$('#userWork input').addClass('error')
				$('#userWork .zxtgerror').text('请输入工作单位，限20字')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userTel input').removeClass('error')
				$('#userTel .zxtgerror').text('')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userWork').offset().top-150)
				return
			}else if(that.useraddress.trim().length > 40){
				$('#userAddress input').addClass('error')
				$('#userAddress .zxtgerror').text('请输入通讯地址，限40字')
				$('#userWork input').removeClass('error')
				$('#userWork .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userTel input').removeClass('error')
				$('#userTel .zxtgerror').text('')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userAddress').offset().top-150)
				return
			}else if(that.userintroduction.trim() == ''){
				$('#userIntro textarea').addClass('error')
				$('#userIntro .zxtgerror').text('请输入个人简介')
				$('#userWork input').removeClass('error')
				$('#userWork .zxtgerror').text('')
				$('#userAddress input').removeClass('error')
				$('#userAddress .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userTel input').removeClass('error')
				$('#userTel .zxtgerror').text('')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userIntro').offset().top-150)
				return
			}else if(that.userintroduction.trim().length > 1000){
				$('#userIntro textarea').addClass('error')
				$('#userIntro .zxtgerror').text('请输入个人简介，限1000字')
				$('#userWork input').removeClass('error')
				$('#userWork .zxtgerror').text('')
				$('#userAddress input').removeClass('error')
				$('#userAddress .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userResearch input').removeClass('error')
				$('#userResearch .zxtgerror').text('')
				$('#userTel input').removeClass('error')
				$('#userTel .zxtgerror').text('')
				$('#userName input').removeClass('error')
				$('#userName .zxtgerror').text('')
				$('#manuFile .zxtgerror').text('')
				$('#manuAbstract textarea').removeClass('error')
				$('#manuAbstract .zxtgerror').text('')
				$('#manuTheme textarea').removeClass('error')
				$('#manuTheme .zxtgerror').text('')
				$('#manuSummary textarea').removeClass('error')
				$('#manuSummary .zxtgerror').text('')
				$('#manuName input').removeClass('error')
				$('#manuName .zxtgerror').text('')
				$('#manuKeyword .label-selected').removeClass('error')
				$('#manuKeyword .zxtgerror').text('')
				window.scrollTo(0,$('#userIntro').offset().top-150)
				return
			}
			$('#userIntro textarea').removeClass('error')
			$('#userIntro .zxtgerror').text('')
			$('#userAddress input').removeClass('error')
			$('#userAddress .zxtgerror').text('')
			$('#userResearch input').removeClass('error')
			$('#userResearch .zxtgerror').text('')
			$('#userResearch input').removeClass('error')
			$('#userResearch .zxtgerror').text('')
			$('#userTel input').removeClass('error')
			$('#userTel .zxtgerror').text('')
			$('#userName input').removeClass('error')
			$('#userName .zxtgerror').text('')
			$('#manuFile .zxtgerror').text('')
			$('#manuAbstract textarea').removeClass('error')
			$('#manuAbstract .zxtgerror').text('')
			$('#manuTheme textarea').removeClass('error')
			$('#manuTheme .zxtgerror').text('')
			$('#manuSummary textarea').removeClass('error')
			$('#manuSummary .zxtgerror').text('')
			$('#manuName input').removeClass('error')
			$('#manuName .zxtgerror').text('')
			$('#manuKeyword .label-selected').removeClass('error')
			$('#manuKeyword .zxtgerror').text('')
			return true
		},
		ShowKeyWords() {
			$(function() {
				$(".show-labelitem").on("click", function() {
					$(this).hide();
					$(".hide-labelitem").show();
					$("#labelItem").show();
				});
				$(".hide-labelitem").on("click", function() {
					$(this).hide();
					$(".show-labelitem").show();
					$("#labelItem").hide();
				});
				$(".label-item").on("click", "li", function() {
					var id = $(this).attr("data");
					var text = $(this).children("span").html();
					var labelHTML = "<li data='" + id + "'" + "data-name='" + text + "'>" + text + " x</li>";
					if ($(this).hasClass("selected")) {
						return false;
					} else if ($(".label-selected").children("li").length >= 5) {
						layer.msg("最多可以选择5个哦");
						return false;
					}
					$(".label-selected").append(labelHTML);
					val = '';
					valName = '';
					for (var i = 0; i < $(".label-selected").children("li").length; i++) {
						val += $(".label-selected").children("li").eq(i).attr("data") + ',';
						valName += $(".label-selected").children("li").eq(i).attr("data-name") + ',';
					}
					$("#keywords").val(val);
					$("#keywordnames").val(valName);
					$(this).addClass("selected");
				});
				var val = "";
				var valName = "";
				$(".label-selected").on("click", "li", function() {
					var id = $(this).attr("data");
					val = '';
					valName = '';
					$(this).remove();
					for (var i = 0; i < $(".label-selected").children("li").length; i++) {
						val += $(".label-selected").children("li").eq(i).attr("data") + ',';
						valName += $(".label-selected").children("li").eq(i).attr("data-name") + ',';
					}
					$("#keywords").val(val);
					$("#keywordnames").val(valName);
					$(".label-item").find("li[data='" + id + "']").removeClass("selected");
				});
			})
		}
	},
	created() {
		this.GetManuscriptInfo()
		this.GetKeywords()
	},
	components: {
		'Header': Header,
		'Footer': Footer
	}
}
